
// Connect Sass
require("../scss/index.scss");

// SVG Sprites
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../images/sprites', true, /\.svg$/));

// Loading
window.addEventListener('load', () => {
  document.body.classList.remove('before-load');

  document.querySelector('.loading').addEventListener('transitionend', e => {
    document.body.removeChild(e.currentTarget);
  });
});

// jQuery
window.jQuery = window.$ = require('jquery');

// Customizable Scrollbar
document.addEventListener("DOMContentLoaded", function() {
  OverlayScrollbars(document.querySelectorAll('.has-scrollbars'), {
    scrollbars : {
      autoHide:      "leave",
      autoHideDelay: 700,
    }
   });
});

//
// UI Scripts
//
var UIScripts = (function() {

  // Projects Card Hover Event
  function cardHover() {
    if($('.card.has-projects').length) {
      var projects = $('.card.has-projects'),
          cardItem = projects.find('.card__item');
      
      $(cardItem).on('mouseenter', function(t) {
          var i = $(t.currentTarget);
          var e, s, a;
          var r = $(this).find('.card__caption'),
          n = i.width(),
          o = i.height();
          s = (t.pageX - i.offset().left - n / 2) * (n > o ? o / n : 1),
          a = (t.pageY - i.offset().top - o / 2) * (o > n ? n / o : 1),
          0 == (e = Math.round((Math.atan2(a, s) * (180 / Math.PI) + 180) / 90 + 3) % 4) ? r.css({
              top: -o,
              left: 0
          }) : 1 == e ? r.css({
              top: 0,
              left: n
          }) : 2 == e ? r.css({
              top: o,
              left: 0
          }) : r.css({
              top: 0,
              left: -n
          }),
          i.find(".card__caption").stop().animate({
              top: 0,
              left: 0
          }, 260);
      });
      $(cardItem).on('mouseleave', function(t) {
        var i, e, s, a = $(t.currentTarget), r = {}, n = a.width(), o = a.height();
        e = (t.pageX - a.offset().left - n / 2) * (n > o ? o / n : 1),
        s = (t.pageY - a.offset().top - o / 2) * (o > n ? n / o : 1),
        r = 0 == (i = Math.round((Math.atan2(s, e) * (180 / Math.PI) + 180) / 90 + 3) % 4) ? {
            top: -o,
            left: 0
        } : 1 == i ? {
            top: 0,
            left: n
        } : 2 == i ? {
            top: o,
            left: 0
        } : {
            top: 0,
            left: -n
        },
        a.find('.card__caption').stop().animate(r, 260, function() {
          $(this).css({
              top: 0,
              left: -n
          });
        });
      });
    }
  }

  // DOMContentLoaded
  window.addEventListener('DOMContentLoaded', function() {
    cardHover();
  });
  
  // Scroll
  window.addEventListener('scroll', function() {
  });
})();